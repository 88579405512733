// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-child-protection-policy-js": () => import("./../../../src/templates/child-protection-policy.js" /* webpackChunkName: "component---src-templates-child-protection-policy-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-donate-js": () => import("./../../../src/templates/donate.js" /* webpackChunkName: "component---src-templates-donate-js" */),
  "component---src-templates-donation-status-js": () => import("./../../../src/templates/donation-status.js" /* webpackChunkName: "component---src-templates-donation-status-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-financials-js": () => import("./../../../src/templates/financials.js" /* webpackChunkName: "component---src-templates-financials-js" */),
  "component---src-templates-how-to-join-js": () => import("./../../../src/templates/how-to-join.js" /* webpackChunkName: "component---src-templates-how-to-join-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-innovated-js": () => import("./../../../src/templates/innovated.js" /* webpackChunkName: "component---src-templates-innovated-js" */),
  "component---src-templates-innovation-js": () => import("./../../../src/templates/innovation.js" /* webpackChunkName: "component---src-templates-innovation-js" */),
  "component---src-templates-life-as-a-fellow-js": () => import("./../../../src/templates/life-as-a-fellow.js" /* webpackChunkName: "component---src-templates-life-as-a-fellow-js" */),
  "component---src-templates-life-as-alumni-js": () => import("./../../../src/templates/life-as-alumni.js" /* webpackChunkName: "component---src-templates-life-as-alumni-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-na-js": () => import("./../../../src/templates/NA.js" /* webpackChunkName: "component---src-templates-na-js" */),
  "component---src-templates-our-impact-js": () => import("./../../../src/templates/our-impact.js" /* webpackChunkName: "component---src-templates-our-impact-js" */),
  "component---src-templates-our-partners-js": () => import("./../../../src/templates/our-partners.js" /* webpackChunkName: "component---src-templates-our-partners-js" */),
  "component---src-templates-our-people-js": () => import("./../../../src/templates/our-people.js" /* webpackChunkName: "component---src-templates-our-people-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-regions-js": () => import("./../../../src/templates/regions.js" /* webpackChunkName: "component---src-templates-regions-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-support-us-js": () => import("./../../../src/templates/support-us.js" /* webpackChunkName: "component---src-templates-support-us-js" */),
  "component---src-templates-values-js": () => import("./../../../src/templates/values.js" /* webpackChunkName: "component---src-templates-values-js" */),
  "component---src-templates-volunteer-js": () => import("./../../../src/templates/volunteer.js" /* webpackChunkName: "component---src-templates-volunteer-js" */),
  "component---src-templates-what-we-do-js": () => import("./../../../src/templates/what-we-do.js" /* webpackChunkName: "component---src-templates-what-we-do-js" */)
}

